<template>
  <div class="box">
    <div class="box-top">
      <img src="../../assets/img/xuanchuantu.png" alt="" class="xuanchuantu">
    </div>
    <div class="beijing">
          <div class="xiazaix">
            <a href="https://www.hxnc.cc">
                  <img src="../../assets/img/lijixiazai.png" alt="" class="xiazia">
              </a>
          </div>
          <div class="box-center">
              <div class="box-ceters">
                  <div class="box-master">
                    <div class="box-list">
                      <div class="box-text">领养人:</div>
                      <div class="box-span">{{pigDetal.owner_name}}</div>
                    </div>
                    <div class="box-list">
                      <div class="box-text">猪崽编号：</div>
                      <div class="box-span">{{pigDetal.pig_num}}</div>
                    </div>
                    <div class="box-list">
                      <div class="box-text">领养时间：</div>
                      <div class="box-span">{{pigDetal.adopt_time}}</div>
                    </div>
                    <div class="box-list">
                      <div class="box-text">喂养时长：</div>
                      <div class="box-span">{{pigDetal.feed_month}}个月</div>
                    </div>
                    <div class="box-list" v-if="pigDetal.butcher_time">
                      <div class="box-text">宰杀时间：</div>
                      <div class="box-span">{{pigDetal.butcher_time}}</div>
                    </div>
                  </div>
              </div>
          </div>
          <div class="box-center">
              <div class="box-cetersl">
                  <div class="box-master">
                    <van-tabs
                    v-model="active"
                    title-active-color="#FE1E00"
                    title-inactive-color="#ffc824"
                    background="#FFFFEB"
                >
                  <van-tab title="喂养记录" >
                    <div v-if="feedList.length != 0">
                      <div
                        class="div-content"
                        v-for="item in feedList"
                        :key="item[0].date"
                      >
                        <div>
                          <p class="time">{{ item[0].date }}</p>
                          <div class="infos" v-for="dd in item" :key="dd.id">

                            <p class="info_time">{{ dd.time }}</p>
                            <div class="left-right">
                              <div class="displays">
                                <p
                                  class="info_text"
                                  v-for="val in dd.feed"
                                  :key="val.id"
                                >
                                  <span
                                    class="fuhao"
                                    v-if="
                                      val.cate_id != 2 &&
                                      val.cate_id != 1 &&
                                      val.cate_id != 3
                                    "
                                  >
                                    {{ val.name + '：' + val.weight + '克' }}
                                  </span>
                                  <span class="fuhao" v-if="val.cate_id == 1">
                                    {{ val.name + '：' + val.weight + '份' }}
                                  </span>
                                  <span class="fuhao" v-if="val.cate_id == 2">
                                    {{ val.name }}
                                  </span>
                                  <span class="fuhao" v-if="val.cate_id == 3">
                                    {{ val.name }}
                                  </span>
                                </p>
                              </div>
                              <div
                                class="clickviope"
                                @click="clickviope(dd.video_url)"
                                v-if="dd.video_url"
                              >
                                喂养视频
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div class="moder">  
                        <div
                        class="more"
                        v-if="active == 0 && feedList.length != 0"
                        @click="weiyangjilu"
                      >
                          查看更多
                      </div>
                      </div>

                    </div>
                    <div v-else>
                      <div class="none">暂无数据</div>
                    </div>
                  </van-tab>
                   <van-tab title="防疫记录">
                    <div class="infoText" v-if="pigDetal.prevent">
                      <div
                        class="content"
                        v-for="(item, index) in pigDetal.prevent"
                        :key="index"
                      >
                      <div class="lister">
                        <p class="time">{{ item.add_time }}</p>
                        <div class="medicine">
                          {{ item.prevention }}
                        </div>
                      </div>
                      </div>
                    </div>
                    <div v-else>
                      <div class="none">暂无数据</div>
                    </div>
                  </van-tab>
                </van-tabs>
                  </div>
              </div>
          </div>
          <p class="titile" v-show="titleShow">{{ msg }}</p>
              <van-dialog v-model="viopeshow" :show-confirm-button="false">
                <div class="pot">
                  <div class="tc-viope-box">
                    <div class="weiyang">喂养视频</div>
                    <div class="bfvipoe">
                      <video-player
                        ref="myVideo"
                        :playsinline="true"
                        v-show="viopeshow"
                        :options="playerOptions"
                      >
                      </video-player>
                    </div>
                  </div>
                  <img
                    src="../../assets/img/close.png"
                    alt=""
                    class="close"
                    @click="closevipoe"
                  />
                </div>
              </van-dialog> 
    </div>
    <!-- <div class="content">
      <div class="logoImg">
        <img src="../../assets/img/xuanchuantu.png" alt=""  class="xuanchuantu"/>
      </div>
      <div class="boss">
        <div v-if="pid">
          <div v-if="pigDetal.id" class="mode">
            <div class="video">
              <img :src="pigDetal.image" alt="" />
            </div>
            <div class="info">
              <ul class="ulInfo">
                <li>
                  猪崽编号： <span class="left">{{ pigDetal.pig_num }}</span>
                </li>
                <li>领养时间：<span class="left">{{pigDetal.adopt_time}}</span></li>
                <li v-if="pigDetal.butcher_time">屠宰时间：<span class="left">{{pigDetal.butcher_time}}</span></li>
              </ul>
              <div class="certificate">
                <van-tabs
                  v-model="active"
                  title-active-color="#FE1E00"
                  title-inactive-color="#FBEBD3"
                >
                  <van-tab title="防疫记录">
                    <div class="infoText" v-if="pigDetal.prevent">
                      <div
                        class="content"
                        v-for="(item, index) in pigDetal.prevent"
                        :key="index"
                      >
                        <p class="time">{{ item.add_time }}</p>
                        <div class="medicine">
                          {{ item.prevention }}
                        </div>
                      </div>
                    </div>
                    <div v-else>
                      <div class="none">暂无数据</div>
                    </div>
                  </van-tab>
                  <van-tab title="喂养记录">
                    <div v-if="feedList.length != 0">
                      <div
                        class="div-content"
                        v-for="item in feedList"
                        :key="item[0].date"
                      >
                        <div>
                          <p class="time">{{ item[0].date }}</p>
                          <div class="infos" v-for="dd in item" :key="dd.id">
                            <p class="info_time">{{ dd.time }}</p>
                            <div class="left-right">
                              <div class="displays">
                                <p
                                  class="info_text"
                                  v-for="val in dd.feed"
                                  :key="val.id"
                                >
                                  <span
                                    class="fuhao"
                                    v-if="
                                      val.cate_id != 2 &&
                                      val.cate_id != 1 &&
                                      val.cate_id != 3
                                    "
                                  >
                                    {{ val.name + '：' + val.weight + '克' }}
                                  </span>
                                  <span class="fuhao" v-if="val.cate_id == 1">
                                    {{ val.name + '：' + val.weight + '份' }}
                                  </span>
                                  <span class="fuhao" v-if="val.cate_id == 2">
                                    {{ val.name }}
                                  </span>
                                  <span class="fuhao" v-if="val.cate_id == 3">
                                    {{ val.name }}
                                  </span>
                                </p>
                              </div>
                              <div
                                class="clickviope"
                                @click="clickviope(dd.video_url)"
                                v-if="dd.video_url"
                              >
                                喂养视频
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div v-else>
                      <div class="none">暂无数据</div>
                    </div>
                  </van-tab>
                </van-tabs>
              </div>
              <p
                class="more"
                v-if="active == 1 && feedList.length != 0"
                @click="weiyangjilu"
              >
                查看全部喂养记录
              </p>
            </div>
              <a href="https://www.hxnc.cc">
                <img src="../../assets/img/lingyang.png" alt=""  class="lingyang"    >
              </a>
          </div>
        </div>
        <div v-else class="lookUp">
          <van-field
            v-model="number"
            type="number"
            placeholder="请输入编号数字"
          />
          <div>
            <img src="../../assets/img/cha.png" alt="" @click="check" />
          </div>
        </div>
      </div>
    </div>
    <p class="titile" v-show="titleShow">{{ msg }}</p>
    <van-dialog v-model="viopeshow" :show-confirm-button="false">
      <div class="pot">
        <div class="tc-viope-box">
          <div class="weiyang">喂养视频</div>
          <div class="bfvipoe">
            <video-player
              ref="myVideo"
              :playsinline="true"
              v-show="viopeshow"
              :options="playerOptions"
            >
            </video-player>
          </div>
        </div>
        <img
          src="../../assets/img/close.png"
          alt=""
          class="close"
          @click="closevipoe"
        />
      </div>
    </van-dialog> -->
  </div>
</template>

<script>
import { File, record } from '../../api/index'
import { videoPlayer } from 'vue-video-player'
import 'video.js/dist/video-js.css'
import 'videojs-contrib-hls'
export default {
  components: {
    videoPlayer
  },
  data() {
    return {
      active: 0,
      pigDetal: {},
      feedList: [],
      titleShow: false,
      msg: '',
      url: '',
      number: '',
      accessToken: '',
      pid: this.$route.query.pid,
      viopeshow: false,
      playerOptions: {
        autoplay: true,
        muted: true, // 默认情况下将会消除任何音频。
        preload: 'auto', // 建议浏览器在<video>加载元素后是否应该开始下载视频数据。auto浏览器选择最佳行为,立即开始加载视频（如果浏览器支持）
        language: 'zh-CN',
        aspectRatio: '16:9', // 将播放器置于流畅模式，并在计算播放器的动态大小时使用该值。值应该代表一个比例 - 用冒号分隔的两个数字（例如"16:9"或"4:3"）
        fluid: true, // 当true时，Video.js player将拥有流体大小。换句话说，它将按比例缩放以适应其容器。
        sources: [
          {
            // type: 'application/x-mpegURL',
            type: 'video/mp4',
            src: '' // url地址
          }
        ],
        controlBar: {
          timeDivider: false, // 当前时间和持续时间的分隔符
          durationDisplay: false, // 显示持续时间
          remainingTimeDisplay: false, // 是否显示剩余时间功能
          fullscreenToggle: false // 是否显示全屏按钮
        }
      }
    }
  },
  created() {
    if (this.pid) {
      this.initDetail()
      this.recordJl()
    }
  },
  methods: {

    //获取猪崽档案
    async initDetail() {
      this.$store.commit('SET_P_ID')
      let data = {
        p_id: this.pid
      }
      let res = await File(data)
      if (res.data.code != 200) return
      console.log(res.data.data, '档案')
      this.pigDetal = res.data.data
      this.url = res.data.data.monitor_url
    },
    // 查询
    check() {
      if (this.number) {
        this.$router.push({
          url: '/file',
          query: {
            pid: this.number
          }
        })
        this.$router.go(0)
      } else {
        console.log('wu')
        this.msg = '请输入编号'
        this.titleShow = true
        setTimeout(() => {
          this.titleShow = false
        }, 2500)
      }
    },
    //喂养记录
    async recordJl() {
      let data = {
        ls_id: this.pid,
        page: 1,
        days: 9
      }
      let res = await record(data)
      console.log(res,"记录")
      if (res.data.code != 200) return
      this.feedList = res.data.data
    },
    clickviope(e) {
      console.log(e, '视频')
      this.viopeshow = true
      this.playerOptions.sources[0].src = e
    },
    closevipoe() {
      this.viopeshow = false
      this.playerOptions.sources[0].src = ''
    },
    weiyangjilu() {
      this.$router.push({
        path: '/more',
        query: { p_id: this.$route.query.pid }
      })
    }
  }
}
</script>

<style lang="scss" scoped>

.box{
    width: 100%;
    height: 100%;
}

.box-top{
  width: 100vw;
  height: 30vh;
}
.xuanchuantu{
  width: 100vw;
  height: 30vh;
}

.beijing{
  width: 100vw;
  height: 100%;
  padding-bottom: 40vh;
  background: #19B47B;
  
}

.xiazaix{
  display: flex;
  justify-content: space-around;
}
.xiazia{
  width: 400px;
  margin-top: 20px;

}

.box-center{
  width: 100%;
  display: flex;
  justify-content: space-around;
}

.box-ceters{
  width: 80%;
  background:#04935F;
  display: flex;
  justify-content: space-around;
  border-radius: 10px 10px 0px 0px ;
  padding-bottom: 10px ;
}

.box-cetersl{
  width: 80%;
  background:#04935F;
  display: flex;
  justify-content: space-around;
  padding-bottom: 20px ;
}

.box-master{
  width: 94%;
  background: #FFFFEB;
  margin-top: 20px;
  border-radius: 10px;
  padding-top: 20px;
  padding-bottom: 20px;
  font-size: 26px;
  line-height: 50px;
  color: #04935F;
}

.box-text{
  width: 130px;
  margin-left: 40px;
  display: flex;
}

.box-span{
  margin-right: 150px;
}

.box-list{
  display: flex;
}
.content {
  background-position: 100%;
  background-size: 100%;
  background-repeat: no-repeat;
  position: relative;
  display: flex;
  flex-direction: column;
  margin-left: 30px;

  .lister{
    margin-bottom: 20px;
    margin-right: 20px;
    border-radius: 10px;
    background: #FBF6D3 ;
    padding: 20px 0 0 20px;
  }
  .boss {
    // width: 7.8rem;
    // height: 16.5rem;
    width: 687.5px;
    height: 1482px;
    display: flex;
    flex-direction: column;
    align-items: center;
    background-image: url('../../assets/img/file.png');
    background-position: 100%;
    background-size: 100% 100%;
    background-repeat: no-repeat;
    margin-top: 0.2rem;
    box-sizing: border-box;
    .video {
      width: 562.5px;
      height: 320px;
      // width: 6.6rem;
      // height: 4.6rem;
      // margin-top: 1.75rem;
      margin-top: 2.2rem;
      img {
        width: 100%;
        height: 100%;
        // object-fit: contain;
      }
    }
    .info {
      display: flex;
      flex-direction: column;
      align-items: center;
      .name {
        width: 100%;
        font-size: 0.5rem;
        font-weight: bold;
        color: #945614;
        padding-left: 0.9rem;
      }
      .ulInfo {
        width: 100%;
        font-size: 0.4rem;
        font-weight: 500;
        color: #945614;
        margin-left: 0.29rem;
        li {
          margin: 0.2rem 0;
        }
        .left {
          margin-left: 0.5rem;
          font-weight: 600;
        }
      }
      .certificate {
        width: 562.5px;
        height: 444.4px;

        // width: 6.4rem;
        // height: 4.5rem;
        border-radius: 0.2rem;
        background: #ffcead;
        margin-top: 0.2rem;
        position: relative;
        overflow: hidden;

        .van-tabs {
          /deep/ .van-tab__pane {
            // height: 3.5rem;
            // height: 81px;
            height: 354px;
            overflow: hidden;
            overflow-y: scroll;
          }
          .none {
            width: 100%;
            // height: 3.2rem;
            height: 354px;
            display: flex;
            justify-content: center;
            align-items: center;
            font-size: 0.4rem;
            color: #945614;
          }
          .infoText {
            // width: 562.5px;
            width: 100%;
            // width: 6rem;
            // height: 3.5rem;
            height: 354px;
            padding: 0 0.2rem;
            overflow: hidden;
            overflow-y: scroll;
            display: flex;
            flex-direction: column;
            box-sizing: border-box;
            .content {
              // width: 6rem;
              // width: 562.5px;
              width: 100%;

              background: #fbecd3;
              border-radius: 0.05rem;
              box-sizing: border-box;
              padding: 0 0.3rem;
              margin: 0.15rem 0;
              display: flex;
              flex-direction: column;
              align-items: flex-start;
              box-sizing: border-box;
              .time {
                font-size: 0.3rem;
                color: #598F64;
                line-height: 1;
                font-weight: 400;
                padding: 0.2rem 0 0.2rem;
              }
            }
          }


          /deep/ .van-tabs__wrap {
            // height: 0.9rem;
            height: 100px;
            border-bottom: 1px dashed #fbecd3;
          }
          /deep/ .van-tabs__nav {
            background-color: #ffcead;
          }
          /deep/ .van-tabs__line {
            width: 0;
            height: 0;
            border-style: solid;
            border-width: 0.2rem;
            border-color: transparent transparent #fe1e00 transparent;
            background: none;
          }
        }

        .record {
          height: 0.6rem;
          border-bottom: 1px dashed #d79f6d;
          box-sizing: border-box;
          margin: 0 0.2rem;
          padding: 0 0.2rem;
          font-size: 0.34rem;
          line-height: 0.6rem;
          font-weight: 500;
          color: #f9750c;
        }
      }
    }
  }
}
::-webkit-scrollbar {
  display: none;
}
.div-content:last-child {
  padding-bottom: 0.4rem;
}
p {
  margin: 0;
  padding: 0;
}
/deep/ .van-tab__text {
            height: 0.8rem;
            font-size: 0.4rem;
            line-height: 0.8rem;
          }


.shiping{
  width: 1rem;
  height: 0.5rem;
  border-radius: 20px;
  border: 1px solid rgba(150, 110, 9, 0.853);
}
.div-content {
  .time {
    width: 100%;
    font-size: 0.4rem;
    font-weight: 600;
    color: #598F64;
    padding: 0.1rem 0 0 0.3rem;
    box-sizing: border-box;
  }
  .infos {
    // width: 6rem;
    // width: 100%;
    width: 528px;
    background: #000;
    margin: auto;
    margin-top: 0.2rem;
    background: rgba(#FBF6D3, 1);
    border-radius: 0.05rem;
    // padding: 0 0.3rem;
    box-sizing: border-box;
    .left-right {
      display: flex;
      justify-content: space-between;
      align-items: center;
      // padding-right: 0.3rem;
      padding: 0 0.2rem 0.1rem 0.3rem;
      box-sizing: border-box;
    }
    .displays {
      display: flex;
      flex-wrap: wrap;
      line-height: 1;
      // padding-left: 0.3rem;
      // box-sizing: border-box;
    }
    .clickviope {
      // width: 125px;
      width: 165px;
      height: 48px;
      background: #fbe5ba;
      border: 1px solid #945614;
      border-radius: 23px;
      font-size: 22px;
      font-family: Source Han Sans CN;
      font-weight: 500;
      color: #598F64;
      display: flex;
      justify-content: center;
      align-items: center;
      box-sizing: border-box;
    }
    .info_time {
      font-size: 0.35rem;
      color: #598F64;
      padding-left: 0.3rem;
      padding-top: 0.15rem;
      padding-bottom: 0.1rem;
      box-sizing: border-box;
    }
    .info_text {
      font-size: 0.34rem;
      color: #598F64;
      padding-top: 0.1rem;
      font-weight: 600;
    }
    .info_text:not(:last-child)::after {
      content: '、';
    }
    .info_text:last-child {
      padding-bottom: 0.2rem;
    }
  }
}

.logoImg {
  display: flex;
  justify-content: flex-end;
  position: absolute;
  top: 0.2rem;
  left: 0.2rem;
  img {
    width: 3.2rem;
    // height: 2.7rem;
    // width: 687.5px;
    object-fit: contain;
    margin-right: 0.5rem;
  }
}

.medicine {
                width: 100%;
                font-size: 0.35rem;
                color: #598F64;
                font-weight: 900;
                padding-bottom: 0.2rem;
                display: flex;
                justify-content: space-between;
              }
.lookUp {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  img {
    height: 1.1rem;
    margin-top: 0.5rem;
  }
}
.van-field {
  width: 6rem;
  height: 1rem;
  border-radius: 0.13rem;
  background: #ffcead;
  color: #945614;
  overflow: hidden;
  /deep/ .van-field__body {
    height: 100%;
    font-size: 0.4rem;
    color: #945614;
  }
  /deep/ .van-field__control {
    color: #945614;
  }
}
.titile {
  font-size: 0.4rem;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background: rgba(#000, 0.4);
  padding: 0.2rem 0.5rem;
  color: #fff;
  border-radius: 0.2rem;
  z-index: 999999;
}

.none {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  font-size: 0.5rem;
  font-weight: 600;
  color: #945614;
}

// 弹出视频盒子
.van-dialog {
  width: 700px;
  height: 560px;
  // background-color: rgba(0, 0, 0, 0.7);
  // margin: 0 auto;
  background: none;
}
.pot {
  width: 700px;
  height: 560px;
  position: relative;
  // padding: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
  box-sizing: border-box;
  .tc-viope-box {
    // width: 562.5px;
    // height: 320px;
    // background-color: #fff;
    width: 630px;
    // width: 600px;
    height: 460px;
    background: #fbe5ba;
    border-radius: 20px;

    .weiyang {
      width: 630px;
      // width: 600px;
      height: 93px;
      background: #d79f6d;
      border-radius: 20px 20px 0 0;

      font-size: 36px;
      font-family: Source Han Sans CN;
      font-weight: bold;
      color: #935513;
      text-align: center;
      line-height: 93px;
    }
    .bfvipoe {
      width: 540px;
      height: 320px;
      // background: #9fd09a;
      margin: 0 auto;
      margin-top: 23.5px;
      /deep/ #myplayer {
        width: 100%;
        height: 100%;
      }
    }
  }
}
.close {
  width: 100px;
  height: 100px;
  position: absolute;
  // right: 20px;
  right: 0px;
  top: 20px;
  // right: -50px;
  // top: -50px;
  // z-index: 10000000 !important;
}

.lingyang{
  height: 120px;
  position: fixed;
  bottom: 100px;
  left: 22%;
}

.moder{
  display: flex;
  justify-content: space-around;
}
.more {
        width: 250px;
        height: 70px;
        line-height: 70px;
        background: #19B47B;
        margin-top: 0.2rem;
        font-size: 0.4rem;
        font-weight: 500;
        color: #ffffff;
        text-align: center;
      }
</style>
